<template>
  <div>
    <!-- Swiper Slider -->
    <swiper
      :slides-per-view="'auto'"
      :space-between="30"
      :navigation="true"
      :modules="[Navigation]"
      :loop="list.length > 2"
      :breakpoints="{
        '@0.00': {
          spaceBetween: 10,
        },
        '@0.75': {
          spaceBetween: 20,
        },
        '@1.00': {
          spaceBetween: 30,
        },
        '@1.50': {
          spaceBetween: 30,
        },
      }"
      class="imageSlider big-slider"
    >
      <swiper-slide
        v-for="(object, i) in list"
        :key="object + i"
        @click="openPopup(i)"
      >
        <img :src="object.image.url" alt="" class="image cursor-pointer" lazyloading />
      </swiper-slide>
    </swiper>

    <!-- Popup -->
    <div v-if="isPopupOpen && isMediumScreenOrLarger" class="popup">
      <div class="popup-overlay" @click="closePopup"></div>
      <div class="popup-content">
        <button class="close-btn" @click="closePopup">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 60">
              <line
                id="Line 182"
                x1="1.25008"
                y1="1.11028"
                x2="39.5837"
                y2="39.4439"
                stroke="white"
                stroke-width="2"
              />
              <line
                id="Line 183"
                x1="39.0411"
                y1="1.70711"
                x2="0.707493"
                y2="40.0407"
                stroke="white"
                stroke-width="2"
              />
            </g>
          </svg>
        </button>
        <swiper
          ref="popupSwiper"
          :modules="[Navigation]"
          :navigation="true"
          :space-between="30"
          :initial-slide="currentIndex"
          :slides-per-view="1"
          :speed="700"
          class="popup-slider"
          @slideChange="updateCurrentIndex"
          loop
        >
          <swiper-slide
            v-for="(object, i) in list"
            :key="object + i"
            class="flex flex-col justify-center items-center"
          >
            <img :src="object.image.url" alt="" class="popup-image" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick, onMounted, onBeforeUnmount } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["list"],
  setup() {
    const isPopupOpen = ref(false);
    const currentIndex = ref(0);
    const popupSwiper = ref(null);
    const isMediumScreenOrLarger = ref(false);

    // Kontrollera skärmstorlek
    const updateScreenSize = () => {
      isMediumScreenOrLarger.value = window.innerWidth >= 768; // Medium är >= 768px
    };

    onMounted(() => {
      updateScreenSize();
      window.addEventListener("resize", updateScreenSize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScreenSize);
    });

    const openPopup = (index) => {
      if (isMediumScreenOrLarger.value) {
        currentIndex.value = index;
        isPopupOpen.value = true;

        nextTick(() => {
          if (popupSwiper.value?.swiper) {
            popupSwiper.value.swiper.slideTo(index, 0);
          }
        });
      }
    };

    const closePopup = () => {
      isPopupOpen.value = false;
    };

    const updateCurrentIndex = () => {
      if (popupSwiper.value?.swiper) {
        currentIndex.value = popupSwiper.value.swiper.realIndex;
      }
    };

    return {
      isPopupOpen,
      currentIndex,
      popupSwiper,
      openPopup,
      closePopup,
      updateCurrentIndex,
      isMediumScreenOrLarger,
      Navigation,
    };
  },
};
</script>

<style>
/* Popup styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.popup-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90vw;
  height: 100%;
}

.popup-slider {
  width: 100%;
  height: 90%;
}

.popup-image {
  max-width: 100%;
  max-height: 90%;
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 10px;
  background: none;
  color: white;
  font-size: 30px;
  border: none;
  cursor: pointer;
  z-index: 100;
}

.popup .swiper-wrapper {
  align-items: center;
}

.popup .swiper-button-next {
  color: #ffffff !important;
}
.popup .swiper-button-prev {
  color: #ffffff !important;
}
</style>
